import type { z } from "zod";

import type { LoginFormSchema, SignUpFormSchema } from "@vrodex/validators";

import { signInAction, signUpAction } from "~/app/_actions/auth-actions";
import { useLoading } from "~/providers/loading-provider";

export const useAuth = () => {
  const { setIsLoading } = useLoading();
  const signUp = async (data: z.infer<typeof SignUpFormSchema>) => {
    setIsLoading(true);
    const result = await signUpAction(data);
    setIsLoading(false);
    return result;
  };

  const signIn = async (data: z.infer<typeof LoginFormSchema>) => {
    setIsLoading(true);
    const { error } = await signInAction(data);
    if (error) {
      setIsLoading(false);
      return error;
    }

    return null;
  };

  return {
    signUp,
    signIn,
  };
};
