import React from "react";
import Lottie from "react-lottie";

import * as animationData from "./assets/loading-animation.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const LoadingSpinner = () => {
  return (
    <div>
      <Lottie options={defaultOptions} height={75} width={75} />
    </div>
  );
};
