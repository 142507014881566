"use client";

import React, { useEffect, useRef, useState } from "react";
import { useTranslations } from "next-intl";

import { Button } from "@vrodex/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  useForm,
} from "@vrodex/ui/form";
import { Input } from "@vrodex/ui/input";
import { Switch } from "@vrodex/ui/switch";
import { LoginFormSchema } from "@vrodex/validators";

import { useAuth } from "~/hooks/use-auth";

export const LoginForm = ({
  placeHolders,
}: {
  placeHolders: { email: string; password: string; button: string };
}) => {
  const formRef = useRef<HTMLFormElement>(null);

  const t = useTranslations("LoginForm");

  const [incorrectCredentials, setIncorrectCredentials] = useState(false);

  const { signIn } = useAuth();

  const [remember, setRemember] = useState(false);

  const [user, setUser] = useState<string | null>(null);

  const parsedUser = user
    ? (JSON.parse(user) as { user: string; password: string })
    : null;

  const form = useForm({
    schema: LoginFormSchema,
    defaultValues: {
      user: parsedUser ? parsedUser.user : "",
      password: parsedUser ? parsedUser.password : "",
    },
  });

  const onSubmit = form.handleSubmit(async (data) => {
    setIncorrectCredentials(false);
    if (remember) {
      localStorage.setItem(
        "user",
        JSON.stringify({
          user: data.user,
          password: data.password,
        }),
      );
    } else {
      localStorage.removeItem("user");
    }
    const result = await signIn(data);
    if (result) {
      setIncorrectCredentials(true);
    }
  });

  const handleRememberChange = (value: boolean) => {
    setRemember(value);
    if (value === true) {
      localStorage.setItem("remember", "true");
    } else {
      localStorage.removeItem("remember");
    }
  };

  useEffect(() => {
    setUser(localStorage.getItem("user"));
    setRemember(localStorage.getItem("remember") === "true");
  }, []);

  return (
    <Form {...form}>
      <form ref={formRef} onSubmit={onSubmit} className="flex flex-col gap-4">
        <FormField
          control={form.control}
          name="user"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  type="email"
                  placeholder={placeHolders.email}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  type="password"
                  placeholder={placeHolders.password}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex flex-row items-center justify-between gap-2">
          <label
            htmlFor="remember"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            {t("remember_me")}
          </label>
          <Switch
            id="remember"
            checked={remember}
            onCheckedChange={handleRememberChange}
          />

          <FormMessage />
        </div>
        <Button variant="success" type="submit">
          {placeHolders.button}
        </Button>
        {incorrectCredentials && (
          <div className="flex items-center justify-center">
            <span className="text-sm font-bold text-red-500">
              {t("incorrect_credentials")}
            </span>
          </div>
        )}
      </form>
    </Form>
  );
};
