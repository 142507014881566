"use client";

import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslations } from "next-intl";

import { Button } from "@vrodex/ui/button";
import { LoadingSpinner } from "@vrodex/ui/loading-spinner";

interface ILoadingContext {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

const LoadingContext = createContext<ILoadingContext | undefined>(undefined);

export const useLoading = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be within a LoadingProvider");
  }
  return context;
};

const LoadingProvider = React.memo(
  ({ children }: { children: React.ReactNode }) => {
    const [isLoading, setIsLoading] = useState(false);

    const [waitingTime, setWaitingTime] = useState(0);

    const t = useTranslations("Loading");

    const loadingContext: ILoadingContext = useMemo(
      () => ({
        isLoading,
        setIsLoading,
      }),
      [isLoading, setIsLoading],
    );

    useEffect(() => {
      if (!isLoading) {
        setWaitingTime(0);
        return;
      }
      const incrementIdleTime = () => {
        setWaitingTime((prevIdleTime) => prevIdleTime + 100);
      };

      const timer = setInterval(incrementIdleTime, 100); // Increment idle time every split second

      return () => {
        clearInterval(timer);
      };
    }, [isLoading]);

    return (
      <LoadingContext.Provider value={loadingContext}>
        {isLoading && (
          <div className="absolute inset-0 z-[99999999] bg-background/50">
            <div className="flex flex-col h-full w-full items-center justify-center gap-2">
              <LoadingSpinner />
              {waitingTime > 10000 && (
                <div className="mx-auto flex max-w-md flex-col gap-1">
                  <p className="text-center text-sm">
                    {t("loading_long_description")}
                  </p>
                  <Button onClick={() => window.location.reload()}>
                    {t("loading_long_button")}
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}

        {children}
      </LoadingContext.Provider>
    );
  },
);

LoadingProvider.displayName = "LoadingProvider";

export default LoadingProvider;
